<template>
  <v-container>
    <v-row>
      <v-col sm="12" md="8" lg="6">
        <v-stepper :alt-labels="true" v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step
                :complete="currentStep > 1"
                step="1"
                editable
            >
              {{ $t('input') }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="currentStep > 2"
                step="2"
            >
              {{ $t('result') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form v-model="valid" ref="form">
                <v-text-field
                    :label="$t('taxableIncomeKanton')"
                    filled
                    prefix="CHF"
                    color="none"
                    type="text"
                    pattern="\d*"
                    inputmode="numeric"
                    :rules="incomeRules"
                    :value="form.steuerbaresEinkommenKanton | formatNumber"
                    @input="incomeValueChange($event)"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                        {{ $t('taxableIncomeCantonInfo') }}
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-text-field
                    :label="$t('taxableIncomeBund')"
                    filled
                    prefix="CHF"
                    color="none"
                    type="text"
                    pattern="\d*"
                    inputmode="numeric"
                    :rules="incomeRules"
                    :value="form.steuerbaresEinkommenBund | formatNumber"
                    @input="incomeValueBundChange($event)"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('taxableIncomeBundInfo') }}
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-text-field
                    filled
                    prefix="CHF"
                    color="none"
                    type="text"
                    pattern="\d*"
                    inputmode="numeric"
                    :rules="wealthRules"
                    :label="$t('taxableWealth')"
                    :value="form.steuerbaresVermoegen | formatNumber"
                    @input="wealthValueChange($event)"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('taxableWealthInfo') }}
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-autocomplete
                    ref="ersteWohngemeinde"
                    v-model="form.ersteWohngemeinde"
                    :rules="[() => !!form.ersteWohngemeinde || $t('requiredField')]"
                    :items="municipalitiesFirst"
                    :loading="loadingAutocompleteFrist"
                    :search-input.sync="searchOldWohngemeinde"
                    :label="$t('addressOld')"
                    :placeholder="$t('pleaseSelect')"
                    hide-no-data
                    filled
                    color="none"
                    required
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('oldDomicileInfo') }}
                    </v-tooltip>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    ref="zweiteWohngemeinde"
                    v-model="form.zweiteWohngemeinde"
                    :items="municipalitiesSecond"
                    :loading="loadingAutocompleteSecond"
                    :search-input.sync="searchNewWohngemeinde"
                    :label="$t('addressNew')"
                    :placeholder="$t('pleaseSelect')"
                    hide-no-data
                    filled
                    color="none"
                    required
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('oldDomicileInfo') }}
                    </v-tooltip>
                  </template>
                </v-autocomplete>
                <v-select
                    :items="zivilstaende"
                    v-model="form.zivilstand"
                    item-text="text"
                    item-value="value"
                    filled
                    color="none"
                    :rules="[() => !!form.zivilstand || $t('requiredField')]"
                    :label="$t('civilStatus')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('civilStateInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-select
                    :items="anzahlKinder"
                    v-model="form.anzahlKinder"
                    filled
                    color="none"
                    :rules="[() => form.anzahlKinder >= 0 || $t('requiredField')]"
                    :label="$t('numberOfChildren')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('kidsInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-select
                    :items="konfessionen"
                    v-model="form.konfession"
                    filled
                    item-text="text"
                    item-value="value"
                    color="none"
                    :rules="[() => !!form.konfession || $t('requiredField')]"
                    :label="$t('confession')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('confessionInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-btn
                    elevation="2"
                    large
                    class="calc-button"
                    @click="calculate()"
                >{{ $t('calculate') }}
                </v-btn>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div v-if="calculationResult">
                <h2 class="calc-header">{{ $t('resultOverview') }}</h2>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <th></th>
                      <td class="text-right"><b>{{ calculationResult.gemeindeAlt.gemeinde }}</b></td>
                      <td v-if="calculationResult.gemeindeNeu" class="text-right"><b>{{ calculationResult.gemeindeNeu.gemeinde }}</b></td>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('taxBund') }}</th>
                      <td class="text-right">{{ calculationResult.gemeindeAlt.steuerBund | formatNumber }} CHF</td>
                      <td v-if="calculationResult.gemeindeNeu" class="text-right">{{ calculationResult.gemeindeNeu.steuerBund | formatNumber }} CHF</td>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('taxCanton') }}</th>
                      <td class="text-right">{{ calculationResult.gemeindeAlt.steuerKanton | formatNumber }} CHF</td>
                      <td v-if="calculationResult.gemeindeNeu" class="text-right">{{ calculationResult.gemeindeNeu.steuerKanton | formatNumber }} CHF</td>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('taxMunicipality') }}</th>
                      <td class="text-right">{{ calculationResult.gemeindeAlt.steuerGde | formatNumber }} CHF</td>
                      <td v-if="calculationResult.gemeindeNeu" class="text-right">{{ calculationResult.gemeindeNeu.steuerGde | formatNumber }} CHF</td>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('taxChurch') }}</th>
                      <td class="text-right">{{ calculationResult.gemeindeAlt.steuerKirche | formatNumber }} CHF</td>
                      <td v-if="calculationResult.gemeindeNeu" class="text-right">{{ calculationResult.gemeindeNeu.steuerKirche | formatNumber }} CHF</td>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('taxPersonal') }}</th>
                      <td class="text-right">{{ calculationResult.gemeindeAlt.steuerPersonal | formatNumber }} CHF</td>
                      <td v-if="calculationResult.gemeindeNeu" class="text-right">{{ calculationResult.gemeindeNeu.steuerPersonal | formatNumber }} CHF</td>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('total') }}</th>
                      <td class="text-right"><b>{{ calculationResult.gemeindeAlt.steuerTotal | formatNumber }} CHF</b>
                      </td>
                      <td v-if="calculationResult.gemeindeNeu" class="text-right"><b>{{ calculationResult.gemeindeNeu.steuerTotal | formatNumber }} CHF</b>
                      </td>
                    </tr>
                    <tr v-if="calculationResult.gemeindeNeu">
                      <th class="text-left">{{ $t('difference') }}</th>
                      <td class="text-right"></td>
                      <td class="text-right"><b>{{getDifferencePrefix(calculationResult.differenceAbs) }}{{
                           calculationResult.differenceAbs | formatNumber
                        }} CHF</b></td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div>
                  <v-alert
                      class="difference"
                      border="left"
                      colored-border
                      color="red accent-4"
                      elevation="2"
                      v-if="calculationResult.gemeindeNeu && calculationResult.differenceAbs > 0"
                  >
                    {{ $t('resultMessageIncrease', { oldDomicile: calculationResult.gemeindeAlt.gemeinde,
                                              newDomicile: calculationResult.gemeindeNeu.gemeinde,
                                              difference: getDifferenceAbsolutFormatter(calculationResult.differenceAbs)  ,
                                              percentage: Math.abs(calculationResult.differencePercentage),
                                              }) }}
                  </v-alert>
                  <v-alert
                      class="difference"
                      border="left"
                      colored-border
                      color="red accent-4"
                      elevation="2"
                      v-if="calculationResult.gemeindeNeu && calculationResult.differenceAbs < 0"
                  >
                    {{ $t('resultMessageDecrease', { oldDomicile: calculationResult.gemeindeAlt.gemeinde,
                    newDomicile: calculationResult.gemeindeNeu.gemeinde,
                    difference: getDifferenceAbsolutFormatter(calculationResult.differenceAbs)  ,
                    percentage: Math.abs(calculationResult.differencePercentage),
                  }) }}
                  </v-alert>
                  <v-alert
                      v-if="calculationResult.gemeindeNeu && calculationResult.differenceAbs === 0"
                      class="difference"
                      border="left"
                      colored-border
                      color="red accent-4"
                      elevation="2"
                  >
                    {{ $t('resultNoDifference', { oldDomicile: calculationResult.gemeindeAlt.gemeinde,
                    newDomicile: calculationResult.gemeindeNeu.gemeinde
                  }) }}
                  </v-alert>
                </div>
                <v-btn
                    class="reclaculation-button"
                    elevation="2"
                    large
                    @click="reCalculate()"
                >{{ $t('newInput') }}
                </v-btn>
                <div class="bar-chart">
                  <h2 class="calc-header">{{ $t('graphicResult') }}</h2>
                  <tax-chart :data="calculationResult"></tax-chart>
                </div>
                <div class="disclaimer">
                  <p>{{ $t('withoutWarranty') }}</p>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        {{ $t('dataSources') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ $t('disclaimer') }}
                        <i18n path="madeBy" tag="label" for="novalytica">
                          <a href="http://novalytica.com" target="_blank">{{ $t('novalytica') }}</a>
                        </i18n>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-overlay :value="loadingSpinner">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <div class="error-wrapper" v-if="error">
          <span class="error--text">{{ error }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TaxChart from "@/components/TaxChart";
import axios from "axios";

export default {
  name: 'TaxCalculator',
  components: {TaxChart},
  data:  function () { return {
    form: {
      steuerbaresVermoegen: null,
      steuerbaresEinkommenKanton: null,
      steuerbaresEinkommenBund: null,
      ersteWohngemeinde: null,
      zweiteWohngemeinde: null,
      zivilstand: null,
      anzahlKinder: 0,
      konfession: null
    },
    searchOldWohngemeinde: null,
    searchNewWohngemeinde: null,
    loadingAutocompleteFrist: false,
    loadingAutocompleteSecond: false,
    loadingSpinner: false,
    valid: false,
    calculated: false,
    calculationResult: null,
    error: null,
    konfessionen: [
      {text: this.$t('reformiert'), value: "reformiert"},
      {text: this.$t('reformiertKatholisch'), value: "römisch-katholisch"},
      {text: this.$t('christKatholisch'), value: "christ-katholisch"},
      {text: this.$t('andere'), value: "andere"},
      {text: this.$t('keine'), value: "keine"}
      ],
    anzahlKinder: [
      0, 1, 2, 3, 4, 5
    ],
    zivilstaende: [
      {text: this.$t('ledig'), value: "Ledig"},
      {text: this.$t('verheiratet'), value: "Verheiratet"},
      {text: this.$t('konkubinat'), value: "Konkubinat"},
      {text: this.$t('eingetragenePartnerschaft'), value: "Eingetragene Partnerschaft"},
      {text: this.$t('geschieden'), value: "Geschieden"},
      {text: this.$t('verwitwet'), value: "Verwitwet"}
    ],
    municipalitiesFirst: [],
    municipalitiesSecond: [],
    currentStep: 1,


  }},
  watch: {
    searchOldWohngemeinde(val) {
      if (val && val !== this.form.ersteWohngemeinde) {
        this.loadingAutocompleteFrist = true
        this.searchForMunicipality(val)
            .then(result => {
              this.loadingAutocompleteFrist = false
              this.municipalitiesFirst = result
            })
            .catch(() => this.loadingAutocompleteFrist = false)
      }
    },
    searchNewWohngemeinde(val) {
      if (val && val !== this.form.zweiteWohngemeinde) {
        this.loadingAutocompleteSecond = true
        this.searchForMunicipality(val)
            .then(result => {
              this.loadingAutocompleteSecond = false
              this.municipalitiesSecond = result
            })
            .catch(() => this.loadingAutocompleteSecond = false)
      }
    }
  },
  computed: {
    numberRules() {
      const rules = []
      const rule1 =
          v => !!v || this.$t('requiredField')

      rules.push(rule1)


      const rule2 =
          (v) => {
            const number = v.split("'").join("");
            if ((Number.isInteger(Number(number)) && Number(number) >= 0)) {
              return true;
            } else {
              return this.$t('positiveNumber')
            }
          };

      rules.push(rule2)
      return rules
    },
    wealthRules() {
      const rules = this.numberRules.slice();
      const ruleMaxMore =
          (v) => {
            const number = v.split("'").join("")
            if ((Number.isInteger(Number(number)) && Number(number) < 100000000)) {
              return true;
            } else {
              return this.$t('maxValue', {value: '100\'000\'000'})
            }
          };
      rules.push(ruleMaxMore);
      return rules;
    },
    incomeRules() {
      const rules = this.numberRules.slice();
      const ruleMax =
          (v) => {
            const number = v.split("'").join("")
            if ((Number.isInteger(Number(number)) && Number(number) < 10000000)) {
              return true;
            } else {
              return this.$t('maxValue', {value: '10\'000\'000'})
            }
          };
      rules.push(ruleMax);
      return rules;
    }
  },
  methods: {
    calculate() {
      console.log('calculating')
      let isValid = this.$refs.form.validate();
      if (isValid) {
        this.loadingSpinner = true;
        this.startCalculation();
      }
    },
    getDifferenceAbsolutFormatter(difference) {
      return this.$options.filters.formatNumber(Math.abs(difference))
    },
    getDifferencePrefix(difference) {
      if (difference > 0) {
        return '+'
      }
    },
    getWordForDifference(difference) {
      if (difference <= 0) {
        return this.$t('decrease')
      } else {
        return this.$t('increase')
      }
    },
    incomeValueChange(value) {
      this.form.steuerbaresEinkommenKanton = parseInt(value.split("'").join(""));
    },
    incomeValueBundChange(value) {
      this.form.steuerbaresEinkommenBund = parseInt(value.split("'").join(""));
    },
    wealthValueChange(value) {
      this.form.steuerbaresVermoegen = parseInt(value.split("'").join(""));
    },
    searchForMunicipality(value) {
      this.loading = true
      console.log('calling backend' + process.env.VUE_APP_BACKEND_URL)
      return axios
          .get(`${process.env.VUE_APP_BACKEND_URL}/autocomplete?query=${value}`)
          .then(response => {
            return response.data;
          }).catch(error => {
            return error;
          })

    },
    startCalculation() {
      return axios
          .post(`${process.env.VUE_APP_BACKEND_URL}/calculation`, this.form)
          .then(response => {
            this.calculationResult = response.data;
            this.currentStep = 2;
            this.loadingSpinner = false;
            this.error = null;
            return this.calculationResult;
          }).catch(error => {
            console.error('Error while calculating tax rates ', error)
            this.loadingSpinner = false;
            this.error = this.$t('errorMessage');
            return error;
          })
    },
    reCalculate() {
      this.currentStep = 1;
    }
  },
}
</script>
<style scoped lang="scss">

.bar-chart {
  margin-top: 3rem;
  max-height: 500px;
}

.reclaculation-button {
  margin-top: 3rem;
}

.error-wrapper {
  margin: 2rem;
}

th, tr, td {
  font-size: 16px !important;

  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}

.v-btn {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.calc-header {
  margin-bottom: 2rem;
}

.disclaimer {
  margin: 4rem 2px 1rem;
}

.difference {
  margin-top: 2rem;
  margin-right: 1rem;
}


</style>