<script>
import {HorizontalBar} from 'vue-chartjs'
import numeral from "numeral";

export default {
  extends: HorizontalBar,
  props: {
    data: {},
  },
  watch: {
    data: function() {
      this._data._chart.destroy();
      this.renderTaxChart();
    }
  },
  mounted() {
    this.renderTaxChart();
  },
  methods: {
    renderTaxChart() {
      let labels;
      let dataBund;
      let dataKanton;
      let dataGemeinde;
      let dataChurch;
      let dataPersonal;
      if (this.data.gemeindeNeu) {
        labels = [this.data.gemeindeAlt.gemeinde, this.data.gemeindeNeu.gemeinde];
        dataBund = [this.data.gemeindeAlt.steuerBund, this.data.gemeindeNeu.steuerBund];
        dataKanton = [this.data.gemeindeAlt.steuerKanton, this.data.gemeindeNeu.steuerKanton];
        dataGemeinde = [this.data.gemeindeAlt.steuerGde, this.data.gemeindeNeu.steuerGde];
        dataChurch = [this.data.gemeindeAlt.steuerKirche, this.data.gemeindeNeu.steuerKirche];
        dataPersonal = [this.data.gemeindeAlt.steuerPersonal, this.data.gemeindeNeu.steuerPersonal];
      } else {
        labels = [this.data.gemeindeAlt.gemeinde];
        dataBund = [this.data.gemeindeAlt.steuerBund];
        dataKanton = [this.data.gemeindeAlt.steuerKanton];
        dataGemeinde = [this.data.gemeindeAlt.steuerGde];
        dataChurch = [this.data.gemeindeAlt.steuerKirche];
        dataPersonal = [this.data.gemeindeAlt.steuerPersonal];
      }
      this.renderChart({
            labels: labels,
            datasets: [
              {
                label: this.$t('taxBund') + ' ' +  this.$t('inChf') ,
                backgroundColor: '#e7e7de',
                data: dataBund,
                barThickness: 25,
              },
              {
                label: this.$t('taxCanton') + ' ' + this.$t('inChf') ,
                backgroundColor: '#008891',
                data: dataKanton,
                barThickness: 25,
              },
              {
                label: this.$t('taxMunicipality') + ' ' + this.$t('inChf') ,
                backgroundColor: '#00587a',
                data: dataGemeinde,
                barThickness: 25,
              },
              {
                label: this.$t('taxChurch') + ' ' + this.$t('inChf') ,
                backgroundColor: '#0f5755',
                data: dataChurch,
                barThickness: 25,
              },
              {
                label: this.$t('taxPersonal') + ' ' + this.$t('inChf'),
                backgroundColor: '#0f1157',
                data: dataPersonal,
                barThickness: 25,
              },
            ],
          }, {
            scales: {
              xAxes: [{
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: this.$t('taxAmount'),
                },
                ticks: {
                  callback: function (value) {
                    return numeral(value).format("0,0")
                  }
                }
              }],
              yAxes: [{
                stacked: true,
                ticks: {
                  callback: function (value) {
                    const splitted = value.split(' ');
                    splitted.shift();
                    return splitted;
                  }
                }
              }]
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                fontFamily: 'Roboto'
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
                  return datasetLabel + ': ' + numeral(tooltipItem.xLabel).format("0,0")
                },
              }
            }
          }
      )

    }
  },
}
</script>

<style scoped lang="scss">

#horizontalbar-chart {
}
</style>