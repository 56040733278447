import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n'
import {translations} from "@/translations/translations";
import numeral from 'numeral';
import router from './router'

Vue.config.productionTip = false


Vue.use(VueI18n)

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

numeral.register('locale', 'de', {
  delimiters: {
    thousands: "'",
  },
  currency: {
    symbol: 'CHF'
  }
});

numeral.locale('de');

const i18n = new VueI18n({
  locale: 'de',
  messages: translations,
})



new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
