<template>
  <v-container>
    <v-row>
      <v-col sm="12" md="8" lg="6">
        <v-stepper :alt-labels="true" v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step
                :complete="currentStep > 1"
                step="1"
                editable
            >
              {{ $t('input') }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="currentStep > 2"
                step="2"
            >
              {{ $t('result') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form v-model="valid" ref="quellensteuerForm">
                <v-text-field
                    :label="$t('incomePerMonth')"
                    filled
                    prefix="CHF"
                    color="none"
                    type="text"
                    pattern="\d*"
                    inputmode="numeric"
                    :rules="numberRules"
                    :value="form.monatlichesBruttoEinkommen | formatNumber"
                    @input="incomeValueChange($event)"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                        {{ $t('incomePerMonthInfo') }}
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-autocomplete
                    ref="wohnkanton"
                    v-model="form.wohnkanton"
                    :rules="[() => !!form.wohnkanton || $t('requiredField')]"
                    :items="cantons"
                    item-text="fullName"
                    item-value="fullName"
                    :label="$t('cantonOfResidence')"
                    :placeholder="$t('pleaseSelect')"
                    hide-no-data
                    filled
                    color="none"
                    required
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('cantonOfResidenceInfo') }}
                    </v-tooltip>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    ref="vergleichsKanton"
                    v-model="form.vergleichsKanton"
                    :items="cantons"
                    item-text="fullName"
                    item-value="fullName"
                    :label="$t('comparisonCanton')"
                    :placeholder="$t('pleaseSelect')"
                    hide-no-data
                    filled
                    color="none"
                    required
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('comparisonCantonInfo') }}
                    </v-tooltip>
                  </template>
                </v-autocomplete>
                <v-select
                    :items="erwerbstaetigkeiten"
                    v-model="form.artDerErwerbstaetigkeit"
                    filled
                    color="none"
                    item-value="value"
                    item-text="text"
                    :rules="[() => !!form.artDerErwerbstaetigkeit || $t('requiredField')]"
                    :label="$t('employment')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('employmentInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-select
                    :items="zivilstaende"
                    v-model="form.zivilstand"
                    filled
                    item-text="text"
                    item-value="value"
                    @change="zivilstandChange"
                    color="none"
                    :rules="[() => !!form.zivilstand || $t('requiredField')]"
                    :label="$t('civilStatus')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('civilStateQuellensteuerInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-select
                    v-if="form.zivilstand === 'Verheiratet' || form.zivilstand === 'Eingetragene Partnerschaft'"
                    :items="yerOrNo"
                    v-model="form.doppelVerdiener"
                    filled
                    item-text="text"
                    item-value="value"
                    color="none"
                    :rules="doppelVerdienerRules"
                    :label="$t('doubleIncome')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('doubleIncomeInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-select
                    :items="anzahlKinder"
                    v-model="form.anzahlKinder"
                    filled
                    color="none"
                    :rules="[() => form.anzahlKinder >= 0 || $t('requiredField')]"
                    :label="$t('numberOfChildren')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('kidsQuellensteuerInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-select
                    :items="konfessionen"
                    v-model="form.konfession"
                    filled
                    item-text="text"
                    item-value="value"
                    color="none"
                    :rules="[() => !!form.konfession || $t('requiredField')]"
                    :label="$t('confession')"
                >
                  <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      {{ $t('confessionQuellensteuerInfo') }}
                    </v-tooltip>
                  </template>
                </v-select>
                <v-btn
                    elevation="2"
                    large
                    @click="calculate()"
                >{{ $t('calculate') }}
                </v-btn>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div v-if="calculationResult">
                <h2 class="calc-header">{{ $t('resultOverviewQuellensteuer') }}</h2>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <th></th>
                      <td class="text-right"><b>{{ calculationResult.wohnort.kanton}}</b></td>
                      <td v-if="calculationResult.vergleichsWohnort" class="text-right"><b>{{ calculationResult.vergleichsWohnort.kanton }}</b></td>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('quellensteuer') }}</th>
                      <td class="text-right">{{ calculationResult.wohnort.quellensteuer | formatNumber }} CHF</td>
                      <td v-if="calculationResult.vergleichsWohnort" class="text-right">{{ calculationResult.vergleichsWohnort.quellensteuer | formatNumber }} CHF</td>
                    </tr>
                    <tr   v-if="calculationResult.vergleichsWohnort">
                      <th class="text-left">{{ $t('difference') }}</th>
                      <td class="text-right"></td>
                      <td class="text-right"><b>{{getDifferencePrefix(calculationResult.differenceAbs) }}{{
                          calculationResult.differenceAbs | formatNumber
                        }} CHF</b></td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div>
                  <v-alert
                      v-if="calculationResult.vergleichsWohnort && calculationResult.differenceAbs > 0"
                      class="difference"
                      border="left"
                      colored-border
                      color="red accent-4"
                      elevation="2"
                  >
                    {{ $t('resultMessageIncrease', { oldDomicile: calculationResult.wohnort.kanton,
                                              newDomicile: calculationResult.vergleichsWohnort.kanton,
                                              difference: getDifferenceAbsolutFormatter(calculationResult.differenceAbs)  ,
                                              percentage: Math.abs(calculationResult.differencePercentage),
                                              }) }}
                  </v-alert>
                  <v-alert
                      v-if="calculationResult.vergleichsWohnort && calculationResult.differenceAbs < 0"
                      class="difference"
                      border="left"
                      colored-border
                      color="red accent-4"
                      elevation="2"
                  >
                    {{ $t('resultMessageDecrease', { oldDomicile: calculationResult.wohnort.kanton,
                    newDomicile: calculationResult.vergleichsWohnort.kanton,
                    difference: getDifferenceAbsolutFormatter(calculationResult.differenceAbs)  ,
                    percentage: Math.abs(calculationResult.differencePercentage),
                  }) }}
                  </v-alert>
                  <v-alert
                      v-if="calculationResult.vergleichsWohnort && calculationResult.differenceAbs === 0"
                      class="difference"
                      border="left"
                      colored-border
                      color="red accent-4"
                      elevation="2"
                  >
                    {{ $t('resultNoDifference', { oldDomicile: calculationResult.wohnort.kanton,
                    newDomicile: calculationResult.vergleichsWohnort.kanton
                  }) }}
                  </v-alert>
                </div>
                <v-btn
                    class="reclaculation-button"
                    elevation="2"
                    large
                    @click="reCalculate()"
                >{{ $t('newInput') }}
                </v-btn>
                <div class="bar-chart">
                  <h2 class="calc-header">{{ $t('graphicResult') }}</h2>
                  <quellensteuer-chart :data="calculationResult"></quellensteuer-chart>
                </div>
                <div class="disclaimer">
                  <p>{{ $t('withoutWarranty') }}</p>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        {{ $t('dataSources') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ $t('disclaimerQuellensteuer') }}
                        <i18n path="madeByQuellensteuer" tag="label" for="novalytica">
                          <a href="http://novalytica.com" target="_blank">{{ $t('novalytica') }}</a>
                        </i18n>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-overlay :value="loadingSpinner">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <div class="error-wrapper" v-if="error">
          <span class="error--text">{{ error }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import QuellensteuerChart from "@/components/QuellensteuerChart";
import {cantons} from "@/components/cantons";

export default {
  components: {QuellensteuerChart},
  data: function () { return {
    form: {
      monatlichesBruttoEinkommen: null,
      wohnkanton: null,
      vergleichsKanton: null,
      artDerErwerbstaetigkeit: null,
      zivilstand: null,
      doppelVerdiener: null,
      konfession: null,
      anzahlKinder: 0,
    },
    yerOrNo: [
      {text: this.$t('yes'), value: true},
      {text: this.$t('no'), value: false}
    ],
    loadingSpinner: false,
    valid: false,
    calculated: false,
    calculationResult: null,
    error: null,
    cantons: cantons,
    erwerbstaetigkeiten: [
      {text: this.$t('haupterwerb'), value: "Haupterwerb"},
      {text: this.$t('nebenerwerb'), value: "Nebenerwerb"}
    ],
    konfessionen: [
      {text: this.$t('reformiert'), value: "reformiert"},
      {text: this.$t('reformiertKatholisch'), value: "römisch-katholisch"},
      {text: this.$t('christKatholisch'), value: "christ-katholisch"},
      {text: this.$t('andere'), value: "andere"},
      {text: this.$t('keine'), value: "keine"}
    ],
    anzahlKinder: [
      0, 1, 2, 3, 4, 5
    ],
    zivilstaende: [
      {text: this.$t('ledig'), value: "Ledig"},
      {text: this.$t('verheiratet'), value: "Verheiratet"},
      {text: this.$t('eingetragenePartnerschaft'), value: "Eingetragene Partnerschaft"},
      {text: this.$t('geschieden'), value: "Geschieden"},
      {text: this.$t('verwitwet'), value: "Verwitwet"}
    ],
    currentStep: 1,


  }},
  computed: {
    numberRules() {
      const rules = []
      const rule1 =
          v => !!v || this.$t('requiredField')

      rules.push(rule1)


      const rule2 =
          (v) => {
            const number = v.split("'").join("")
            if ((Number.isInteger(Number(number)) && Number(number) >= 0)) {
              return true;
            } else {
              return this.$t('positiveNumber')
            }
          };

      const ruleMax =
          (v) => {
            const number = v.split("'").join("")
            if ((Number.isInteger(Number(number)) && Number(number) <= 80000)) {
              return true;
            } else {
              return this.$t('maxValue', {value: '80\'000'})
            }
          };
      rules.push(ruleMax)
      rules.push(rule2)
      return rules
    },
    doppelVerdienerRules() {
      const rules = []
      const rule2 =
          (v) => {
            if (v !== null) {
              return true;
            } else {
              return this.$t('requiredField')
            }
          };

      rules.push(rule2)
      return rules
    }
  },
  methods: {
    calculate() {
      let isValid = this.$refs.quellensteuerForm.validate();
      if (isValid) {
        this.loadingSpinner = true;
        this.startCalculation();
      }
    },
    getDifferenceAbsolutFormatter(difference) {
      return this.$options.filters.formatNumber(Math.abs(difference))
    },
    getDifferencePrefix(difference) {
      if (difference > 0) {
        return '+'
      }
    },
    zivilstandChange() {
      this.form.doppelVerdiener = null;
    },
    getWordForDifference(difference) {
      if (difference <= 0) {
        return this.$t('decrease')
      } else {
        return this.$t('increase')
      }
    },
    incomeValueChange(value) {
      this.form.monatlichesBruttoEinkommen = parseInt(value.split("'").join(""));
    },
    startCalculation() {
      return axios
          .post(`${process.env.VUE_APP_BACKEND_URL}/calculation/quellensteuer`, this.form)
          .then(response => {
            this.calculationResult = response.data;
            this.currentStep = 2;
            this.loadingSpinner = false;
            this.error = null;
            return this.calculationResult;
          }).catch(error => {
            console.error('Error while calculating tax rates ', error)
            this.loadingSpinner = false;
            this.error = this.$t('errorMessage');
            return error;
          })
    },
    reCalculate() {
      this.currentStep = 1;
    }
  },
}
</script>
<style scoped lang="scss">

.bar-chart {
  margin-top: 3rem;
  max-height: 500px;
}

.reclaculation-button {
  margin-top: 3rem;
}


.error-wrapper {
  margin: 2rem;
}

th, tr, td {
  font-size: 16px !important;

  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}

.v-btn {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.calc-header {
  margin-bottom: 2rem;
}

.disclaimer {
  margin: 4rem 2px 1rem;
}

.difference {
  margin-top: 2rem;
  margin-right: 1rem;
}

</style>