export const translations = {
    de: {
        input: 'Eingabe',
        result: 'Resultat',
        taxableIncomeCantonInfo: 'Das steuerbare Einkommen Kanton entnehmen Sie aus der Steuererklärung oder der Steuerrechnung.',
        taxableIncomeBundInfo: 'Das steuerbare Einkommen Bund entnehmen Sie aus der Steuererklärung oder der Steuerrechnung.',
        taxableWealthInfo: 'Das steuerbare Vermögen entnehmen Sie aus der Steuererklärung oder der Steuerrechnung.',
        oldDomicileInfo: 'Bitte wählen Sie Ihren Wohnort aus. Bitte selektieren Sie den Wohnort mit der korrekten Wohngemeinde, falls ein PLZ-Gebiet zu mehreren Gemeinden gehört.',
        newDomicileInfo: 'Bitte wählen Sie den neuen Wohnort aus. Bitte selektieren Sie den Wohnort mit der korrekten Wohngemeinde, falls ein PLZ-Gebiet zu mehreren Gemeinden gehört.',
        civilStateInfo: 'Bitte wählen Sie den Zivilstand aus.',
        kidsInfo: 'Bitte wählen Sie die Anzahl Kinder aus.',
        confessionInfo: 'Bitte wählen Sie die Konfession aus. Bei verheirateten Paaren oder bei Paaren in einer eingetragenen Partnerschaft geht der Rechner von einer Konfession aus. Zur Auswahl stehen die in der Schweiz offiziell anerkannten Religionen, die Kirchensteuern erheben.',
        taxableIncomeKanton: 'Steuerbares Einkommen Kanton',
        taxableIncomeBund: 'Steuerbares Einkommen Bund',
        taxableWealth: 'Steuerbares Vermögen',
        addressOld: 'Wohnort bisher (Name oder PLZ)',
        addressNew: 'Wohnort neu (Name oder PLZ)',
        requiredField: 'Diese Feld ist obligatorisch',
        maxValue: 'Der Wert muss kleiner {value} sein',
        positiveNumber: 'Zahl muss positiv sein',
        numberOfChildren: 'Anzahl Kinder',
        confession: 'Konfession',
        civilStatus: 'Zivilstand',
        pleaseSelect: 'Bitte wählen...',
        calculate: 'Berechnen',
        resultOverview: 'Steuerbelastung Übersicht',
        resultOverviewQuellensteuer: 'Steuerbelastung pro Monat',
        taxBund: 'Bundessteuer',
        inChf: 'in CHF',
        taxCanton: 'Kantonssteuer',
        taxMunicipality: 'Gemeindesteuer',
        taxChurch: 'Kirchensteuer',
        taxPersonal: 'Personalsteuer',
        taxAmount: 'Steuerbelastung in CHF',
        total: 'Total',
        difference: 'Differenz',
        resultMessage: 'Beim Umzug von {oldDomicile} nach {newDomicile} nimmt die Steuerbelastung um {difference} CHF oder {percentage}%',
        resultMessageIncrease: 'Beim Umzug von {oldDomicile} nach {newDomicile} nimmt die Steuerbelastung um {difference} CHF oder {percentage}% zu.',
        resultMessageDecrease: 'Beim Umzug von {oldDomicile} nach {newDomicile} nimmt die Steuerbelastung um {difference} CHF oder {percentage}% ab.',
        resultNoDifference: 'Beim Umzug von {oldDomicile} nach {newDomicile} bleibt die Steuerbelastung unverändert.',
        increase: 'zu',
        decrease: 'ab',
        ledig: 'Ledig',
        verheiratet: 'Verheiratet',
        konkubinat: 'Konkubinat',
        eingetragenePartnerschaft: 'Eingetragene Partnerschaft',
        geschieden: 'Geschieden',
        verwitwet: 'Verwitwet',
        reformiert: 'reformiert',
        reformiertKatholisch: 'römisch-katholisch',
        christKatholisch: 'christ-katholisch',
        andere: 'andere',
        keine: 'keine',
        newInput: 'Neue Eingabe',
        graphicResult: 'Grafische Darstellung',
        withoutWarranty: 'Alle Angaben ohne Gewähr.',
        dataSources: 'Steuerberechnung und Datenquelle',
        disclaimer: 'Die Berechnung der Steuerbelastung erfolgt basierend auf den Daten der Eidgenössischen Steuerverwaltung für das Steuerjahr 2023. Die Berechnung geht davon aus, dass bei verheirateten Paaren beide die gleiche Konfession haben. st.',
        madeBy: 'Der Steuerrechner wird von der {0} betrieben.',
        novalytica: 'Novalytica AG',
        errorMessage: 'Leider konnte die Berechnung nicht ausgeführt werden. Bitte überprüfen Sie Ihre Eingaben.',
        incomePerMonth: 'Bruttoeinkommen pro Monat in CHF',
        quellensteuer: 'Quellensteuer',
        cantonOfResidence: 'Wohnkanton',
        comparisonCanton: 'Vergleichskanton',
        employment: 'Erwerbstätigkeit',
        doubleIncome: 'Doppelverdiener',
        yes: 'Ja',
        no: 'Nein',
        haupterwerb: 'Haupterwerb',
        nebenerwerb: 'Nebenerwerb',
        studiumPraktikum: 'Studium/Praktikum',
        incomePerMonthInfo: 'Der monatliche Bruttolohn inkl. aller Zulagen (z.B. Essens-, Kinder- und Wegzulagen oder Trinkgelder) stellt die Berechnungsgrundlage für die Quellensteuer dar. Zulagen werden dem Monat zugewiesen, in dem die Auszahlung erfolgt.',
        cantonOfResidenceInfo: 'Wohnsitzkanton der quellensteuerpflichtigen Person.',
        comparisonCantonInfo: 'Bitte wählen Sie einen Vergleichskanton aus',
        employmentInfo: 'Handelt es sich um einen Haupt- oder Nebenerwerb? Abhängig vom Wohnkanton können unterschiedliche Steuersätze zur Anwendung kommen.',
        civilStateQuellensteuerInfo: 'Abhängig vom Zivilstand kommt ein unterschiedlicher Tarif der Quellensteuer zur Anwendung.',
        doubleIncomeInfo: 'Wenn beide Partner erwerbstätig sind, kommt ein anderer Tarif zur Anwendung.',
        confessionQuellensteuerInfo: 'Zur Auswahl stehen die in der Schweiz offiziell anerkannten Religionen, die Kirchensteuern erheben.',
        kidsQuellensteuerInfo: 'Bitte wählen Sie die Anzahl Kinder aus, für welche Sie finanziell sorgen.',
        disclaimerQuellensteuer: 'Die Berechnung der Quellensteuer erfolgt basierend auf den Daten der Eidgenössischen Steuerverwaltung für das Steuerjahr 2024. Der Rechner ist nicht für die Berechnung der Quellensteuer von Grenzgängern geeignet.',
        madeByQuellensteuer: 'Der Quellensteuerrechner wird von der {0} betrieben.'
    },
    fr: {
        input: 'Entrée',
        result: 'Résultats',
        taxableIncomeCantonInfo: "Vous pouvez trouver le revenu imposable cantonal sur votre déclaration d'impôt ou votre facture d'impôt.",
        taxableIncomeBundInfo: "Vous pouvez trouver votre revenu imposable au niveau fédéral sur votre déclaration d'impôts ou votre facture d'impôts.",
        taxableWealthInfo: "Vous pouvez trouver la fortune imposable sur votre déclaration d'impôts ou votre facture fiscale.",
        oldDomicileInfo: 'Veuillez sélectionner votre lieu de résidence. Veuillez sélectionner le lieu de résidence avec la commune de résidence correcte si un code postal appartient à plusieurs communes.',
        newDomicileInfo: 'Veuillez sélectionner le nouveau lieu de résidence. Veuillez sélectionner le lieu de résidence avec la commune de résidence correcte si un code postal appartient à plusieurs communes.',
        civilStateInfo: "Veuillez sélectionner l'état civil.",
        kidsInfo: "Veuillez sélectionner le nombre d'enfants.",
        confessionInfo: 'Veuillez sélectionner la dénomination. Pour les couples mariés ou les couples en partenariat enregistré, le calcul supposera une dénomination. Les religions officiellement reconnues en Suisse qui perçoivent des impôts ecclésiastiques peuvent être sélectionnées.',
        taxableIncomeKanton: 'Revenu imposable canton',
        taxableIncomeBund: 'Revenu imposable Confédération',
        taxableWealth: 'Fortune imposable',
        addressOld: 'Lieu de domicile (Nom ou CP)',
        addressNew: 'Nouveau lieu de domicile (Nom ou CP)',
        requiredField: 'Champ obligatoire!',
        maxValue: 'La valeur doit être inférieure à {value}.',
        positiveNumber: 'Le nombre doit être positif',
        numberOfChildren: 'Enfants',
        confession: 'Confession',
        civilStatus: 'État civil',
        pleaseSelect: 'Veuillez sélectionner...',
        calculate: 'Calculer',
        resultOverview: 'Aperçu de la charge fiscale',
        resultOverviewQuellensteuer: 'Aperçu de la charge fiscale',
        taxBund: 'Impôt fédéral direct',
        inChf: 'en CHF',
        taxCanton: 'Impôt cantonal',
        taxMunicipality: 'Impôt communal',
        taxChurch: 'Impôt paroissial',
        taxPersonal: 'Impôt personnel',
        taxAmount: 'Charge fiscale en CHF',
        total: 'Total',
        difference: 'Différence',
        resultMessage: 'Lors du passage de {oldDomicile} à {newDomicile}, la charge fiscale est réduite de {difference} CHF ou {percentage}%',
        resultMessageIncrease: 'Lors du passage de {oldDomicile} à {newDomicile}, la charge fiscale augmente de {difference} CHF ou {percentage}%.',
        resultMessageDecrease: 'Lors du passage de {oldDomicile} à {newDomicile}, la charge fiscale est réduite de {difference} CHF ou {percentage}%.',
        resultNoDifference: 'Lors du passage de {oldDomicile} à {newDomicile}, la charge fiscale reste inchangée.',
        increase: '',
        decrease: '',
        ledig: 'Célibataire',
        verheiratet: 'Marié(e)',
        konkubinat: 'En concubinage',
        eingetragenePartnerschaft: 'Partenariat enregistré',
        geschieden: 'Divorcée',
        verwitwet: 'Veuve',
        reformiert: 'Réformée',
        reformiertKatholisch: 'Catholique-romain(e)',
        christKatholisch: 'Catholique-chrétien(ne)',
        andere: 'Autre / Sans',
        keine: 'Aucun',
        newInput: 'Nouvelle entrée',
        graphicResult: 'Représentation graphique',
        withoutWarranty: 'Toutes les données sans garantie.',
        dataSources: "Calcul de l'impôt et source des données",
        disclaimer: "Le calcul de la charge fiscale est basé sur les données de l'Administration fédérale des impôts pour l'année fiscale 2023. Le calcul suppose que les couples mariés ont la même confession religieuse.",
        madeBy: 'Le calculateur d’impôts est géré par {0}.',
        novalytica: 'Novalytica AG',
        errorMessage: "Malheureusement, le calcul n'a pas pu être effectué. Veuillez vérifier vos données.",
        incomePerMonth: 'Revenu brut par mois en CHF',
        quellensteuer: 'Impôt à la source',
        cantonOfResidence: 'Canton de résidence',
        comparisonCanton: 'Comparaison des cantons',
        employment: 'Emploi rémunéré',
        doubleIncome: 'Double revenu',
        yes: 'Oui',
        no: 'Non',
        haupterwerb: 'Occupation principale',
        nebenerwerb: 'Activité complémentaire',
        studiumPraktikum: 'Études/stages',
        incomePerMonthInfo: "Le salaire brut mensuel, y compris toutes les indemnités (par exemple, les indemnités de repas, d'enfant et de déplacement ou les pourboires), constitue la base de calcul de la retenue à la source. Les allocations sont attribuées au mois durant lequel elles sont versées.",
        cantonOfResidenceInfo: "Canton de résidence de la personne soumise à l'impôt à la source.",
        comparisonCantonInfo: 'Veuillez sélectionner un canton pour la comparaison',
        employmentInfo: "S'agit-il d'une profession principale ou secondaire ? Des taux d'imposition différents peuvent s'appliquer selon le canton de résidence.",
        civilStateQuellensteuerInfo: "Selon l'état civil, un taux d' impôt à la source différent est appliqué.",
        doubleIncomeInfo: "Si les deux partenaires sont employés, un taux différent s'applique.",
        confessionQuellensteuerInfo: 'Vous pouvez choisir parmi les religions officiellement reconnues en Suisse qui prélèvent des impôts ecclésiastiques.',
        kidsQuellensteuerInfo: "Veuillez sélectionner le nombre d'enfants pour lesquels vous apportez une aide financière.",
        disclaimerQuellensteuer: "Le calcul d'impôt à la source est basé sur les données de l'Administration fédérale des impôts pour l'année fiscale 2024. Le programme n'est pas adapté pour le calcul de la retenue d'impôt des frontaliers.",
        madeByQuellensteuer: "Le programme de la retenue d'impôt est géré par {0}."
    },
    it: {
        input: 'Input',
        result: 'Risultato',
        taxableIncomeCantonInfo: 'Il reddito imponibile cantonale è riportato sulla dichiarazione dei redditi o sulla fattura fiscale.',
        taxableIncomeBundInfo: 'Potete trovare il vostro reddito imponibile federale sulla dichiarazione dei redditi o sulla fattura fiscale.',
        taxableWealthInfo: 'Potete trovare la sostanza imponibile sulla vostra dichiarazione dei redditi o sulla fattura fiscale.',
        oldDomicileInfo: "Si prega di selezionare il luogo di residenza. Si prega di selezionare il comune di residenza esatto se un'area con un codice di avviamento postale appartiene a più comuni.",
        newDomicileInfo: "Si prega di selezionare il nuovo luogo di residenza. Si prega di selezionare il comune di residenza esatto se un'area con un codice di avviamento postale appartiene a più comuni.",
        civilStateInfo: 'Selezionare lo stato civile.',
        kidsInfo: 'Si prega di selezionare il numero di bambini.',
        confessionInfo: 'Selezionare la confessione. Per le coppie sposate o le coppie in unione domestica registrata, il calcolatore considera una confessione. Le religioni ufficialmente riconosciute in Svizzera che riscuotono le tasse ecclesiastiche sono disponibili per la selezione.',
        taxableIncomeKanton: 'Reddito imponibile Cantone',
        taxableIncomeBund: 'Reddito imponibile Confederazione',
        taxableWealth: 'Sostanza imponibile',
        addressOld: 'Luogo di domiclio fino ad ora (Nome o CAP)',
        addressNew: 'Nuovo luogo di domiclio (Nome o CAP)',
        requiredField: 'Campo obbligatorio',
        maxValue: 'Il valore deve essere inferiore a {value}',
        positiveNumber: 'Il numero deve essere positivo',
        numberOfChildren: 'Numero di figli',
        confession: 'Confessione',
        civilStatus: 'Stato civile',
        pleaseSelect: 'Si prega di selezionare...',
        calculate: 'Calcolare',
        resultOverview: 'Panoramica della pressione fiscale',
        resultOverviewQuellensteuer: 'Panoramica della pressione fiscale mensile',
        taxBund: 'Imposta federale diretta',
        inChf: 'in CHF',
        taxCanton: 'Imposta cantonale',
        taxMunicipality: 'Imposta comunale',
        taxChurch: 'Imposta di culto',
        taxPersonal: 'Imposta personale',
        taxAmount: 'Onere fiscale in CHF',
        total: 'Totale',
        difference: 'Differenza',
        resultMessage: "Quando si passa da {oldDomicile} a {newDomicile} l'onere fiscale aumenta di {difference} CHF o {percentage}%.",
        resultMessageIncrease: "Quando si passa da {oldDomicile} a {newDomicile} l'onere fiscale aumenta di {difference} CHF o {percentage}%.",
        resultMessageDecrease: "Quando si passa da {oldDomicile} a {newDomicile} l'onere fiscale diminuisce di {difference} CHF o {percentage}%.",
        resultNoDifference: "Quando si passa da {oldDomicile} a {newDomicile} l'onere fiscale rimane invariato.",
        increase: '',
        decrease: '',
        ledig: 'Celibe/Nubile',
        verheiratet: 'Sposato/a',
        konkubinat: 'Concubinato',
        eingetragenePartnerschaft: 'Unione domestica registrata',
        geschieden: 'Divorziato/a',
        verwitwet: 'Vedovo/a',
        reformiert: 'Riformata',
        reformiertKatholisch: 'Cattolica romana',
        christKatholisch: 'Cattolica cristiana',
        andere: 'Altra/nessuna',
        keine: 'Nessuno',
        newInput: 'Nuovo input',
        graphicResult: 'Rappresentazione grafica',
        withoutWarranty: 'Tutti i dati senza garanzia.',
        dataSources: 'Calcolo delle imposte e fonte dei dati',
        disclaimer: "Il calcolo dell'onere fiscale si basa sui dati dell'Amministrazione federale delle contribuzioni per l'anno fiscale 2023. Il calcolo presuppone che le coppie sposate abbiano la stessa confessione religiosa.",
        madeBy: 'Il calcolatore delle imposte è gestito da {0}.',
        novalytica: 'Novalytica AG',
        errorMessage: 'Purtroppo non è stato possibile effettuare il calcolo. Si prega di controllare i dati inseriti.',
        incomePerMonth: 'Reddito lordo mensile in CHF',
        quellensteuer: 'Imposta alla fonte',
        cantonOfResidence: 'Cantone di residenza',
        comparisonCanton: 'Cantone di confronto',
        employment: 'Lavoro remunerativo',
        doubleIncome: 'Doppio guadagno',
        yes: 'Sì',
        no: 'No',
        haupterwerb: 'Occupazione principale',
        nebenerwerb: 'Attività secondaria',
        studiumPraktikum: 'Studio/stage',
        incomePerMonthInfo: "Lo stipendio lordo mensile con tutte le indennità (ad es. pasti, assegni per figli e viaggi o mance) rappresenta la base di calcolo dell'imposta alla fonte. Le indennità sono assegnate al mese in cui vengono versate.",
        cantonOfResidenceInfo: "Cantone di domicilio della persona soggetta all'imposta alla fonte.",
        comparisonCantonInfo: 'Selezionare un cantone per il confronto',
        employmentInfo: "Si tratta di un'occupazione principale o secondaria? A seconda del Cantone di residenza possono essere applicate aliquote d'imposta diverse.",
        civilStateQuellensteuerInfo: "A seconda dello stato civile, viene applicata un'aliquota d'imposta alla fonte diversa.",
        doubleIncomeInfo: 'Se entrambi i partner sono attivi professionalmente, si applica una tariffa diversa.',
        confessionQuellensteuerInfo: 'Potete scegliere tra le religioni ufficialmente riconosciute in Svizzera che riscuotono le tasse ecclesiastiche.',
        kidsQuellensteuerInfo: 'Si prega di selezionare il numero di bambini a carico',
        disclaimerQuellensteuer: "Il calcolo dell'imposta preventiva si basa sui dati dell'Amministrazione federale delle contribuzioni per l'anno fiscale 2024. Il programma non è adatto al calcolo dell'imposta preventiva dei frontalieri.",
        madeByQuellensteuer: 'Il programma per la trattenuta alla fonte è gestito da {0}.'
    },
    en: {
        input: 'Input',
        result: 'Result',
        taxableIncomeCantonInfo: 'You find the taxable income for cantonal tax in your tax declaration.',
        taxableIncomeBundInfo: 'You find the taxable income for federal tax in your tax declaration.',
        taxableWealthInfo: 'You can find your taxable assets in the tax declaration.',
        oldDomicileInfo: 'Please select your place of residence. Please select the place of residence with the correct municipality of residence if a postcode area belongs to several municipalities.',
        newDomicileInfo: 'Please select the new place of residence. Please select the place of residence with the correct municipality of residence if a postcode area belongs to several municipalities.',
        civilStateInfo: 'Please select the marital status.',
        kidsInfo: 'Please select the number of children.',
        confessionInfo: 'Please select the religion. For married couples or couples in a registered partnership, the calculator assumes the same religion. Only religions that levy church taxes are available for selection.',
        taxableIncomeKanton: 'Taxable income for cantonal tax',
        taxableIncomeBund: 'Taxable income for federal tax',
        taxableWealth: 'Taxable assets',
        addressOld: 'Place of residence (Postcode or town)',
        addressNew: 'New place of residence (Postcode or town)',
        requiredField: 'Mandatory field',
        maxValue: 'The value must be less than {value}',
        positiveNumber: 'Number must be positive',
        numberOfChildren: 'Children',
        confession: 'Religion',
        civilStatus: 'Marital status',
        pleaseSelect: 'Please select...',
        calculate: 'Calculate',
        resultOverview: 'Tax burden overview',
        resultOverviewQuellensteuer: 'Monthly tax burden',
        taxBund: 'Direct federal tax',
        inChf: 'in CHF',
        taxCanton: 'Cantonal tax',
        taxMunicipality: 'Communal tax',
        taxChurch: 'Church tax',
        taxPersonal: 'Personal tax',
        taxAmount: 'Tax burden in CHF',
        total: 'Total',
        difference: 'Difference',
        resultMessage: 'When moving from {oldDomicile} to {newDomicile} the tax burden decreases by {difference} CHF or {percentage}%',
        resultMessageIncrease: 'When moving from {oldDomicile} to {newDomicile} the tax burden increases by {difference} CHF or {percentage}%.',
        resultMessageDecrease: 'When moving from {oldDomicile} to {newDomicile} the tax burden decreases by {difference} CHF or {percentage}%.',
        resultNoDifference: 'When moving from {oldDomicile} to {newDomicile} the tax burden is not affected.',
        increase: '',
        decrease: '',
        ledig: 'Single',
        verheiratet: 'Married',
        konkubinat: 'Cohabiting',
        eingetragenePartnerschaft: 'Civil partnership',
        geschieden: 'Divorced',
        verwitwet: 'Widowed',
        reformiert: 'Protestant',
        reformiertKatholisch: 'Roman Catholic',
        christKatholisch: 'Christian Catholic',
        andere: 'Other/None',
        keine: 'None',
        newInput: 'New input',
        graphicResult: 'Graphical representation',
        withoutWarranty: 'No responsibility is taken for the accuracy of this information.',
        dataSources: 'Calculation and data source',
        disclaimer: 'The calculation of the tax burden is based on the data of the Federal Tax Administration for the tax year 2023. The calculation assumes that in the case of married couples both have the same religion.',
        madeBy: 'The tax calculator is run by {0}',
        novalytica: 'Novalytica AG',
        errorMessage: 'Unfortunately, the calculation could not be performed. Please check your entries.',
        incomePerMonth: 'Gross income per month in CHF',
        quellensteuer: 'Withholding tax',
        cantonOfResidence: 'Canton of residence',
        comparisonCanton: 'Comparison canton',
        employment: 'Gainful employment',
        doubleIncome: 'Dual earner',
        yes: 'Yes',
        no: 'No',
        haupterwerb: 'Main occupation',
        nebenerwerb: 'Sideline',
        studiumPraktikum: 'Study/Internship',
        incomePerMonthInfo: 'The gross monthly wage including all allowances (e.g. meal, child and travel allowances or tips) constitutes the basis for calculating the withholding tax. Allowances are allocated to the month in which they are paid.',
        cantonOfResidenceInfo: 'Canton of residence of the person liable for withholding tax.',
        comparisonCantonInfo: 'Please select a canton for comparison',
        employmentInfo: 'Is it a main or sideline occupation? Depending on the canton of residence, different tax rates may apply.',
        civilStateQuellensteuerInfo: 'Depending on the marital status, a different rate of withholding tax is applied.',
        doubleIncomeInfo: 'If both partners are employed, a different tax rate applies.',
        confessionQuellensteuerInfo: 'You can choose from the officially recognised religions in Switzerland that levy church taxes. Other religions are "Other" and do not affect the tax burden.',
        kidsQuellensteuerInfo: 'Please select the number of children for whom you provide financial support.',
        disclaimerQuellensteuer: 'The calculation of the withholding tax is based on the data of the Federal Tax Administration for the tax year 2024. The calculator is not suitable for the calculation of the withholding tax of cross-border commuters.',
        madeByQuellensteuer: 'The tax calculator is run by {0}'
    }
}
