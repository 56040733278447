export const cantons = [
    {shortcut: 'AG',	fullName: 'Aargau (AG)'},
    {shortcut: 'AR', fullName: 'Appenzell Ausserrhoden (AR)'},
    {shortcut: 'AI', fullName: 'Appenzell Innerrhoden (AI)'},
    {shortcut: 'BL', fullName: 'Basel-Landschaft (BL)'},
    {shortcut: 'BS', fullName: 'Basel-Stadt (BS)'},
    {shortcut: 'BE', fullName: 'Bern / Berne (BE)'},
    {shortcut: 'FR', fullName: 'Fribourg / Freiburg (FR)'},
    {shortcut: 'GE', fullName: 'Genève (GE)'},
    {shortcut: 'GL', fullName: 'Glarus (GL)'},
    {shortcut: 'GR', fullName: 'Graubünden (GR)'},
    {shortcut: 'JU', fullName: 'Jura (JU)'},
    {shortcut: 'LU', fullName: 'Luzern (LU)'},
    {shortcut: 'NE', fullName: 'Neuchâtel (NE)'},
    {shortcut: 'NW', fullName: 'Nidwalden (NW)'},
    {shortcut: 'OW', fullName: 'Obwalden (OW)'},
    {shortcut: 'SH', fullName: 'Schaffhausen (SH)'},
    {shortcut: 'SZ', fullName: 'Schwyz (SZ)'},
    {shortcut: 'SO', fullName: 'Solothurn (SO)'},
    {shortcut: 'SG', fullName: 'St. Gallen (SG)'},
    {shortcut: 'TG', fullName: 'Thurgau (TG)'},
    {shortcut: 'TI', fullName: 'Ticino (TI)'},
    {shortcut: 'UR', fullName: 'Uri (UR)'},
    {shortcut: 'VS', fullName: 'Valais / Wallis (VS)'},
    {shortcut: 'VD', fullName: 'Vaud (VD)'},
    {shortcut: 'ZG', fullName: 'Zug (ZG)'},
    {shortcut: 'ZH', fullName: 'Zürich (ZH)'}
]