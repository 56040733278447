<script>
import {HorizontalBar} from 'vue-chartjs'
import numeral from "numeral";
import {cantons} from "@/components/cantons";

export default {
  extends: HorizontalBar,
  props: {
    data: {},
    comparisonCanton: String,
    cantonOfResidence: String
  },
  watch: {
    data: function() {
      this._data._chart.destroy();
      this.renderTaxChart();
    }
  },
  mounted() {
    this.renderTaxChart();
  },
  methods: {
    getFullCantonName(fullName) {
      return cantons.find(c => c.fullName === fullName).shortcut;
    },
    renderTaxChart() {
      let labels;
      let data;
      if (this.data.vergleichsWohnort) {
        labels = [this.data.wohnort.kanton, this.data.vergleichsWohnort.kanton];
        data = [this.data.wohnort.quellensteuer, this.data.vergleichsWohnort.quellensteuer];
      } else {
        labels = [this.data.wohnort.kanton];
        data = [this.data.wohnort.quellensteuer];
      }
      this.renderChart({
            labels: labels,
            datasets: [
              {
                label: this.$t('quellensteuer') + ' ' + this.$t('inChf') ,
                backgroundColor: '#008891',
                data: data,
                barThickness: 25,
              }
            ],
          }, {
            scales: {
              xAxes: [{
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: this.$t('taxAmount'),
                },
                ticks: {
                  callback: function (value) {
                    return numeral(value).format("0,0")
                  }
                }
              }],
              yAxes: [{
                stacked: true,
                ticks: {
                  callback: (value) => {
                    return this.getFullCantonName(value)
                  }
                }
              }]
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                fontFamily: 'Roboto'
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
                  return datasetLabel + ': ' + numeral(tooltipItem.xLabel).format("0,0")
                },
              }
            }
          }
      );

    }
  },
}
</script>

<style scoped lang="scss">

#horizontalbar-chart {
}
</style>