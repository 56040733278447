<template>
  <v-app>
    <v-main>
      <tax-calculator v-if="mode === 'regular'"></tax-calculator>
      <quellensteuer-calculator v-if="mode === 'quellensteuer'"></quellensteuer-calculator>
    </v-main>
  </v-app>
</template>

<script>
import TaxCalculator from './components/TaxCalculator';
import QuellensteuerCalculator from "@/components/QuellensteuerCalculator";
export default {
  name: 'App',

  components: {
    QuellensteuerCalculator,
    TaxCalculator
  },

  created() {
    const calculatorType = this.$route.query.mode;
    const language = this.$route.query.lang;
    if (calculatorType) {
      this.mode = calculatorType;
    }
    if (language) {
      this.$i18n.locale = language;
    } else {
      this.$i18n.locale = 'de'
    }
  },

  data: () => ({
    mode: 'regular',
    calcs: ['regular', 'quellensteuer']
  }),
};
</script>
<style scoped lang="scss">
.calc-selection {
  width: 100px;
  margin-right: 1rem;
}
</style>
